/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyD-S3EhQdH66UbS52n0ww8LFlX9XthdnZI",
  "appId": "1:147868275522:web:847df589b8166769d7aedb",
  "authDomain": "schooldog-i-clarke-ga.firebaseapp.com",
  "measurementId": "G-QCVYFR5QX9",
  "messagingSenderId": "147868275522",
  "project": "schooldog-i-clarke-ga",
  "projectId": "schooldog-i-clarke-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-clarke-ga.appspot.com"
}
